.App {
  text-align: center;
}

.ant-tabs-content {
  min-height: 100vh;
}

div[role="toolbar"] {
  display: none;
}
