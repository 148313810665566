.ant-tabs-nav-wrap .ant-tabs-nav-scroll {
    overflow: scroll;
}

.ant-tabs-nav-wrap {
    overflow: hidden;
}

.ant-tabs-bar {
    position: fixed;
    width: 100%;
    background-color: #f0f2f5;
    z-index: 100;
}